import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    products: [],
    productNames: [],
    orders: [],
    loadingProductInfo: false,
    redoCalculations: false,
    loadedAllProductCost: false,
    pages: [],
    currentPageIndex: 0,
    pageSize: 100
  },
  mutations: {
    setProducts(state, products) {
      state.products = products;
    },
    setProductNames(state, productNames) {
      state.productNames = productNames;
    },
    setOrders(state, orders) {
      state.orders = orders;
    },
    setRedoCalculations(state, value) {
      state.redoCalculations = value;
    },
    setloadingProductInfo(state, value) {
      state.loadingProductInfo = value;
    },
    setloadedAllProductCost(state, value) {
      state.loadedAllProductCost = value;
    },
    setPage(state, { pageIndex, products, lastSnapshot }) {
      Vue.set(state.pages, pageIndex, { products, lastSnapshot });
    },
    // Update the current page index.
    setCurrentPageIndex(state, index) {
      state.currentPageIndex = index;
    }
  },
  actions: {
    GetToken({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/getToken', body: data
        }, { root: true }).then(x => {
          resolve(x)
        }).catch(error => {
          reject(error);
        });
      })
    },
    GetCustomToken({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/getCustomToken', body: data
        }, { root: true }).then(x => {
          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    FetchProductsFromDatabase({ commit, dispatch, state }) {
      console.log("fetching initial products from firebase...")
      return new Promise((resolve, reject) => {
        dispatch('api/apiGetWithToken', { path: '/shopify-FetchProducts' },
          { root: true }).then(x => {
            var sortedata = x.data?.sort(function (a, b) {
              return new Date(b.created_at) - new Date(a.created_at);
            }) ?? [];
            if (state.products.length < 1) {
              commit("setProducts", sortedata)
            }
            resolve()
          }).catch(error => {
            reject(error);
          });
      })

    },

    FetchAnalyticsProductName({ commit, dispatch, state }) {
      console.log("fetching product names...")
      return new Promise((resolve, reject) => {
        commit("setloadingProductInfo", true)

        var existing = state.productNames

        if (Object.keys(existing).length > 0) {
          commit("setloadingProductInfo", false)
          resolve(true);
          return true
        }


        dispatch('api/apiPostWithToken', {
          path: '/shopify-FetchProductsNames',
        }, { root: true }).then(x => {

          try {
            var result = x.data

            if (result.length > 0) {
              commit("setProductNames", result)

              commit("setloadingProductInfo", false)
              resolve(true);
              return true
            } else {

              resolve(false);
              return false
            }
          } catch (e) {
            console.log("error trying to parse product names", e)

            resolve(false);
            return false
          }


        }).catch(error => {
          resolve(false);
          return false
        });
      })

    },

    FetchTargetedProductsFromDatabase({ commit, dispatch, state }, IDs) {
      console.log("fetching optimized products from firebase...")
      return new Promise((resolve, reject) => {
        commit("setloadingProductInfo", true)
        var newIDS = [];
        var existing = state.products
        const objectWithIdAsKey = {};

        existing.forEach(obj => {
          objectWithIdAsKey[obj.id] = obj;
        });

        for (const id of IDs) {
          if (objectWithIdAsKey[id] == null) {
            newIDS.push(id)
          }
        }

        dispatch('api/apiPostWithToken', {
          path: '/shopify-FetchProductsIDs',
          body: {
            data: newIDS,
          }
        }, { root: true }).then(x => {

          var result = x.data

          const mergedArray = [...result, ...existing];

          var sortedata = mergedArray.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          }) ?? [];
          commit("setProducts", sortedata)
          commit("setloadingProductInfo", false)
          resolve()
        }).catch(error => {
          console.log(error)
          reject(error);
        });
      })

    },
    FetchTargetedProductsFromDatabaseAdvanced({ commit, dispatch, state }, IDs) {

      return new Promise((resolve, reject) => {

        var newIDS = [];
        var existing = state.products
        const objectWithIdAsKey = {};

        existing.forEach(obj => {
          objectWithIdAsKey[obj.id] = obj;
        });

        for (const id of IDs) {
          if (objectWithIdAsKey[id] == null) {
            newIDS.push(id)
          }
        }
        if (newIDS.length > 0) {
          dispatch('api/apiPostWithToken', {
            path: '/shopify-FetchProductsIDs',
            body: {
              data: newIDS,
            }
          }, { root: true }).then(x => {

            var result = x.data

            const mergedArray = [...result, ...existing];

            var sortedata = mergedArray.sort(function (a, b) {
              return new Date(b.created_at) - new Date(a.created_at);
            }) ?? [];
            commit("setProducts", sortedata)

            resolve("done")
          }).catch(error => {
            console.log(error)
            reject(error);
          });
        } else {
          resolve("done")
        }
      })

    },

    CaculationRequired({ commit }) {
      commit("setRedoCalculations", true)
    },
    FetchNewOrdersInitial({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/orderFetching-FetchNewOrdersInitialNew', body: data
        }, { root: true }).then(x => {

          resolve(x)
        }).catch(error => {
          reject(error);
        });
      })
    },
    async FetchFirstInfo({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/orderFetching-FirstSignUp'
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    FetchFirstMarketing({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/orderFetching-FetchMarketingInitial'
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    async fetchFirstProducts({ commit, state }) {

      try {
        const query = firebase.firestore()
          .collection("product")
          .doc(firebase.auth().currentUser.uid)
          .collection("products")
          .orderBy("created_at", "desc")
          .limit(state.pageSize);

        const snapshot = await query.get();
        const products = snapshot.docs.map(doc =>  doc.data());
        snapshot.docs.forEach(doc => {
     
        });
        // Save the last document snapshot from this page.
        const lastSnapshot = snapshot.docs[snapshot.docs.length - 1] || null;

        console.log("fetch complete first pr", products)
        commit("setPage", { pageIndex: 0, products, lastSnapshot });
        commit("setCurrentPageIndex", 0);
      } catch (error) {
        console.error("Error fetching first products:", error);
      }
    },

    // Fetch the next page using the last snapshot from the current page as the cursor.
    async fetchNextProducts({ commit, state }) {
      try {
        // Check if the next page is already cached.
        if (state.pages[state.currentPageIndex + 1]) {
          commit("setCurrentPageIndex", state.currentPageIndex + 1);
          return;
        }

        // Retrieve the current page's last snapshot to use as a cursor.
        const currentPage = state.pages[state.currentPageIndex];
        if (!currentPage || !currentPage.lastSnapshot) {
          console.warn("No more pages to fetch or missing snapshot.");
          return;
        }

        const query = firebase.firestore()
          .collection("product")
          .doc(firebase.auth().currentUser.uid)
          .collection("products")
          .orderBy("created_at", "desc")
          .startAfter(currentPage.lastSnapshot)
          .limit(state.pageSize);

        const snapshot = await query.get();
        const products = snapshot.docs.map(doc => doc.data());
        const lastSnapshot = snapshot.docs[snapshot.docs.length - 1] || null;

        // Cache the next page.
        commit("setPage", { pageIndex: state.currentPageIndex + 1, products, lastSnapshot });
        // Move to the next page.
        commit("setCurrentPageIndex", state.currentPageIndex + 1);
      } catch (error) {
        console.error("Error fetching next products:", error);
      }
    },

    // Navigate back to the previous page by simply updating the current page index.
    async fetchPreviousProducts({ commit, state }) {
      try {
        if (state.currentPageIndex === 0) {
          // Already on the first page.
          return;
        }
        commit("setCurrentPageIndex", state.currentPageIndex - 1);
      } catch (error) {
        console.error("Error fetching previous products:", error);
      }
    },
    // In shopify.js module (actions)
    async searchProducts({ commit, state }, term) {

      var searchTerm =  term.term
      var pageSize = term.size
      console.log("searchterm", searchTerm, pageSize)
      try {
        // For case-insensitive search, ensure the product documents include a field (e.g., 'title_lowercase')
        // that stores the title in lowercase. Then, you can query like so:
        const lowerSearch = searchTerm.trim().toLowerCase();
        // For example, using a "where" clause or "startAt"/"endAt" with ordering:
        const query = firebase.firestore()
          .collection("product")
          .doc(firebase.auth().currentUser.uid)
          .collection("products")
          .orderBy("title_lowercase")
          .startAt(lowerSearch)
          .endAt(lowerSearch + "\uf8ff")
          .limit(pageSize);

        const snapshot = await query.get();
        const products = snapshot.docs.map(doc => doc.data());
  
        // Optionally update your pages or store these results in a separate property.
        // For this example, we simply return the results.
        return products;
      } catch (error) {
        console.error("Error searching products:", error);
        throw error;
      }
    },
    // In your shopify.js Vuex module:
    async fetchMissingProducts({ commit, state }, productIDs) {
      try {
        // Create chunks of at most 10 IDs each
        const chunkSize = 10;
        const chunks = [];
        for (let i = 0; i < productIDs.length; i += chunkSize) {
          // Convert each ID in the chunk to a Number
          chunks.push(productIDs.slice(i, i + chunkSize).map(id => Number(id)));
        }
    
        let fetchedProducts = [];
    
        // Loop over each chunk and perform a query using Firestore's "in" operator.
        for (const chunk of chunks) {
          const querySnapshot = await firebase.firestore()
            .collection("product")
            .doc(firebase.auth().currentUser.uid)
            .collection("products")
            .where("id", "in", chunk)
            .get();
    
          console.log(`Chunk: ${JSON.stringify(chunk)} returned ${querySnapshot.docs.length} products`);
    
          const products = querySnapshot.docs.map(doc => doc.data());
          fetchedProducts = fetchedProducts.concat(products);
        }
    
        console.log("Fetched Products", fetchedProducts);
        return fetchedProducts;
      } catch (error) {
        console.error("Error fetching missing products:", error);
        throw error;
      }
    }
    

  },
  getters: {
    // Return the products for the current page.
    currentPageProducts(state) {
      const page = state.pages[state.currentPageIndex];
      return page ? page.products : [];
    }
  }
}

function AddFilters(filters) {
  if (filters == null || Object.keys(filters).length == 0)
    return ""

  var arrayWithParams = Object.keys(filters).map(x => {
    return `${x}=${filters[x]}`
  })
  return "?" + arrayWithParams.join("&");
}