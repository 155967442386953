import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueCryptojs from 'vue-cryptojs'
import VueCookie from 'vue-cookie';
import moment from 'moment'
import VueTour from 'vue-tour'
import 'vue-tour/dist/vue-tour.css'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {firebaseApp} from './firebaseConfig'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Global Components
import DefaultPage from '@/components/DefaultPageComponent.vue'
import DefaultPageBlock from '@/components/DefaultPageBlockComponent.vue'
import InfoBanner from '@/components/InfoBanner.vue'
import LoadingBanner from '@/components/LoadingBanner.vue'
import ProBanner from '@/components/ProBanner.vue'
import LocalLoader from '@/components/LocalLoader.vue'
import InformationMarkComponent from '@/components/InformationMarkComponent.vue'
import VDatePicker from 'v-calendar/lib/components/date-picker.umd'





const datepickerOptions = {
  colors: {
    selected: '#00a699',
    inRange: '#66e2da',
    selectedText: '#fff',
    text: '#565a5c',
    inRangeBorder: '#33dacd',
    disabled: '#fff',
    hoveredInRange: '#67f6ee'
  }

}

// Only run this in development mode
if (process.env.NODE_ENV === 'development') {
  const style = document.createElement('style');
  style.textContent = `
    /* Hide the webpack dev server error overlay */
    #webpack-dev-server-client-overlay,
    .webpack-dev-server-overlay {
      display: none !important;
    }
  `;
  document.head.appendChild(style);
}


Vue.prototype.moment = moment
Vue.component('v-date-picker', VDatePicker)
Vue.component("DefaultPage", DefaultPage)
Vue.component("DefaultPageBlock", DefaultPageBlock)
Vue.component("LocalLoader", LocalLoader)
Vue.component("InformationMarkComponent", InformationMarkComponent)
Vue.component("InfoBanner", InfoBanner)
Vue.component("LoadingBanner", LoadingBanner)
Vue.component("ProBanner", ProBanner)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCryptojs)
Vue.use(VueCookie);
Vue.use(VueTour);


Vue.config.productionTip = false

let app = '';
firebase.auth().onAuthStateChanged(user => {
  if(!app){
    app = new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  }
});

